export const setApiError = apierror => ({
    type: 'SET_APIERROR',
    apierror
});

export const setUser = user => ({
    type: 'SET_USER',
    user
});

export const setUserState = userstate => ({
    type: 'SET_USERSTATE',
    userstate
});

export const setNavState = navstate => ({
    type: 'SET_NAVSTATE',
    navstate
});

export const setSaved = saved => ({
    type: 'SET_SAVED',
    saved
});

export const setTopMessage = topmessage => ({
    type: 'SET_TOPMESSAGE',
    topmessage
});

export const setAdminUser = adminuser => ({
    type: 'SET_ADMINUSER',
    adminuser
});

export const setOccupanciesYear = occupoanciesyear => ({
    type: 'SET_OCCUPANCIESYEAR',
    occupoanciesyear
});

export const setOccupanciesListView = occupoancieslistview => ({
    type: 'SET_OCCUPANCIESLISTVIEW',
    occupoancieslistview
});