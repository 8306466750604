import { useState } from "react";
import Cropper from "react-easy-crop";

const ImageCropper = ({ image, onCropDone, onCropCancel, width, height, freepos, round }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState();

    const minZoom = freepos ? '0.3' : '1';
    const restrictPosition = freepos ? false : true;
    const cropShape = round ? 'round' : 'rect';

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    return (
        <div className="cropper">
            <h3>Bild anpassen</h3>
            <p>Bitte beachten Sie, dass zu große Bilder durch die Skalierung unscharf oder pixelig werden können.<br className="hidden-xs" />Wir empfehlen Bilder mit maximal 2000x2000 Pixeln zu verwenden.</p>
            <div className="cropperholder">
                <div className="cropimgholder">
                    <Cropper
                        image={image}
                        aspect={width / height}
                        crop={crop}
                        cropShape={cropShape}
                        zoom={zoom}
                        minZoom={minZoom}
                        restrictPosition={restrictPosition}
                        zoomSpeed={0.1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                </div>
                <div className="controls">
                    <input
                        type="range"
                        className="zoom-range"
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(e) => {
                            setZoom(e.target.value)
                        }}
                    />
                </div>
                <div className="action-btns">
                    <button type="button" className="submit" onClick={() => { onCropDone(croppedArea) }}>Speichern</button>
                    <button type="button" className="cancel" onClick={onCropCancel}>Abbrechen</button>
                </div>
            </div>
        </div>
    );
}

export default ImageCropper;
