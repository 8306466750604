import { useEffect, useState } from 'react';
import ApiService from "../../services/api.service";
import { NavLink } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Input } from '../../components/forms/add/Components';
import HelperService from '../../services/helper.service';


const Password = () => {

    const queryParameters = new URLSearchParams(window.location.search);
    const resethash = queryParameters.get("token");
    const [error, setError] = useState(null);

    if (error) {
        throw error;
    }

    const methods = useForm();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [submitted, setSubmitted] = useState(false);
    const [hashChecked, setHashChecked] = useState(false);

    const [mailInfo, setMailInfo] = useState(false);
    const [passwordResetted, setPasswordResetted] = useState(false);

    useEffect(() => {
        if (resethash) {
            const API_URL = global.config.express.url + '/user/checkpasswordhash';
            const API_DATA = {
                'token': resethash
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    setHashChecked(true);
                }
            }).catch((error) => { setError(error) });
        }
    }, [resethash]);

    const sendEmail = (data) => {
        setSubmitted(true);
        const API_URL = global.config.express.url + '/user/resetpassword';
        const API_DATA = {
            'email': data.email,
            'redirectUrl': window.location.origin + window.location.pathname
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                setMailInfo(true);
            }
        }).catch((error) => {
            setError('NO_CONNECTION')
        });
    }

    const sendNewPassword = (data) => {
        setSubmitted(true);
        const API_URL = global.config.express.url + '/user/setnewpassword';
        const API_DATA = {
            'password': data.password,
            'token': resethash
        }
        ApiService.getData(API_URL, API_DATA).then((response) => {
            if (typeof (response.apierror) !== 'undefined') {
                setError(response.apierror);
            }
            else {
                setPasswordResetted(true);
            }
        }).catch((error) => {
            setError('NO_CONNECTION')
        });
    }


    let cont =
        <>
            <div className='form password'>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(sendEmail)} className="form-add" onKeyDown={(e) => { HelperService.handleFormKeyDown(e) }}>
                        <h2>Passwort vergessen?</h2>
                        <Input register={register} type="text" name="email" label="E-Mail-Adresse" size="" required errors={errors} pattern="email"></Input>
                        <div className="submit_container">
                            <div className="formfieldholder">
                                <button type="submit" className={"submit" + (submitted ? ' load' : '')} disabled={submitted}>Absenden</button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div className='options'>
                <div className='smalltext last'>
                    <NavLink to="/">Zum Login</NavLink>
                </div>
            </div>
        </>;

    if (mailInfo) {
        cont =
            <div className='form password'>
                <form className="form-add">
                    <h2>Eine E-Mail ist unterwegs</h2>
                    <p>Bitte klicken Sie auf den Link in der soeben an Sie versendeten E-Mail. Anschließend können Sie ein neues Passwort vergeben.</p>
                </form>
            </div>
    }

    if (resethash && hashChecked) {
        cont =
            <div className='form password'>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(sendNewPassword)} className="form-add" onKeyDown={(e) => { HelperService.handleFormKeyDown(e) }}>
                        <h2>Neues Passwort vergeben</h2>
                        <Input register={register} type="password" name="password" label="Passwort" size="" required errors={errors} pattern="password"></Input>
                        <div className="submit_container">
                            <div className="formfieldholder">
                                <button type="submit" className={"submit" + (submitted ? ' load' : '')} disabled={submitted}>Absenden</button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
    }

    if (resethash && !hashChecked) {
        cont =
            <>
                <div className='form password'>
                    <form className="form-add">
                        <h2>Neues Passwort vergeben</h2>
                        <p>Der Link ist ungülig oder bereits abgeblaufen. Bitte versuchen Sie es erneut.</p>
                    </form>
                </div>
                <div className='options'>
                    <div className='smalltext last'>
                        <NavLink to="/">Zum Login</NavLink>
                    </div>
                </div>
            </>
    }

    if (passwordResetted) {
        cont = <>
            <div className='form password'>
                <form className="form-add">
                    <h2>Passwort erfolgreich geändert</h2>
                    <p>Ihr neues Passwort wurde hinterlegt. Sie können sich wieder wie gewohnt anmelden.</p>
                </form>
            </div>
            <div className='options'>
                <div className='smalltext last'>
                    <NavLink to="/">Zum Login</NavLink>
                </div>
            </div>
        </>
    }

    return (
    <div className={'fe-wrapper' + (/iPad|iPhone|iPod/.test(navigator.userAgent) ? ' ios-full' : '')}>
            <div className='box'>
                <span className='logo'></span>
                {cont}
            </div>
        </div>
    )
}

export default Password