import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BedIcon from '@mui/icons-material/Bed';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Sidebar = () => {

  const navstate = useSelector(state => state.global.navstate);

  const slideNext = (e) => {
    e.target.classList.toggle("closed")
    e.target.nextSibling.classList.toggle("slide-opened")
  }

  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <ul>
            <li><NavLink to='/'><DashboardIcon className='icon' /><span>Dashboard</span></NavLink></li>
          </ul>
          <br />
          <p className='title' onClick={(e) => slideNext(e)}>Unterkünfte<ExpandMoreIcon className='icon' /></p>
          <div className="slide slide-opened">
            <ul>
              <li><NavLink to='/unterkuenfte'><BedIcon className='icon' /><span>Meine Inserate</span></NavLink></li>
              <li><NavLink to='/anfragen'><ListAltIcon className='icon' /><span>Anfragen</span></NavLink></li>
              <li><NavLink to='/statistiken'><AutoGraphIcon className='icon' /><span>Statistiken</span></NavLink></li>
            </ul>
          </div>
          <p className='title' onClick={(e) => slideNext(e)}>Belohnungssystem<ExpandMoreIcon className='icon' /></p>
          <div className="slide slide-opened">
            <ul>
              <li><NavLink to='/belohnungssystem/informationen'><TipsAndUpdatesIcon className='icon' /><span>Informationen</span></NavLink></li>
              <li><NavLink to='/belohnungssystem/einstellungen'><SettingsIcon className='icon' /><span>Einstellungen</span></NavLink></li>
              <li><NavLink to='/belohnungssystem/belohnungen'><CreditScoreIcon className='icon' /><span>Meine Belohnungen</span></NavLink></li>
            </ul>
          </div>
          <p className='title' onClick={(e) => slideNext(e)}>Tests<ExpandMoreIcon className='icon' /></p>
          <div className="slide slide-opened">
            <ul>
              <li><NavLink to='/mapmarkertest'><LocationOnIcon className='icon' /><span>Map-Marker-Test</span></NavLink></li>
              <li><NavLink to='/mapclustertest'><LocationOnIcon className='icon' /><span>Map-Cluster-Test</span></NavLink></li>
            </ul>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Sidebar 