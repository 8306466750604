import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as GlobalStore from '../../store/global/actions';
import ApiService from "../../services/api.service";
import { NavLink } from 'react-router-dom';
import HelperService from '../../services/helper.service';


const Login = () => {

    const [error, setError] = useState(null);
    const [formError, setFormError] = useState(null);

    if (error) {
        throw error;
    }

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const sendLogin = (e) => {
        e.preventDefault();
        if (username === '' || password === '') {
            setFormError('Bitte alle Felder ausfüllen')
        }
        else {
            const API_URL = global.config.express.url + '/user/login';
            const API_DATA = {
                'username': username,
                'password': password
            }
            ApiService.getData(API_URL, API_DATA).then((response) => {
                if (typeof (response.apierror) !== 'undefined') {
                    setError(response.apierror);
                }
                else {
                    if (response.user) {
                        const isadmin = HelperService.checkAdmin(response.user);

                        if (!isadmin) {
                            const userdata = HelperService.getUserData(response.user);
                            // Hier schon die Userdaten holen um spätere Requests zu vermeiden
                            const API_URL = global.config.express.url + '/user/get';
                            const API_DATA = {
                                'userId': userdata.userId
                            }
                            ApiService.getData(API_URL, API_DATA).then((apiuserdata) => {
                                if (typeof (apiuserdata.apierror) !== 'undefined') {
                                    setError(apiuserdata.apierror);
                                }
                                else {
                                    let data = apiuserdata.result;
                                    response.user.data = data;
                                    dispatch(GlobalStore.setUserState(userdata.status));
                                    dispatch(GlobalStore.setUser(response.user));
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 500);
                                }
                            });
                        }
                        else {
                            dispatch(GlobalStore.setUser(response.user));
                            dispatch(GlobalStore.setAdminUser(response.user));
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        }
                    }
                    else {

                        if (response.loginerror) {
                            setFormError(response.loginerror)
                        }
                    }
                }
            }).catch((error) => {
                setError('NO_CONNECTION')
            });
        }
    }

    return (
        <div className={'fe-wrapper' + (/iPad|iPhone|iPod/.test(navigator.userAgent) ? ' ios-full' : '')}>
            <div className='box'>
                <span className='logo'></span>
                <div className='form login'>
                    <form className='form-add'>
                        <div>
                            <label>E-Mail-Adresse</label>
                            <input type="text" value={username} onChange={(e) => { setFormError(null); setUsername(e.target.value); }} />
                        </div>
                        <div>
                            <label>Passwort</label>
                            <input type="password" value={password} onChange={(e) => { setFormError(null); setPassword(e.target.value); }} />
                        </div>
                        <div>
                            {formError ? <span className='error'>{formError}</span> : ''}
                            <button className='submit' type="submit" onClick={(e) => sendLogin(e)} >Anmelden</button>
                        </div>
                    </form>
                </div>
                <div className='options'>
                    <div className='smalltext'>
                        <NavLink to="/passwort-vergessen">Passwort vergessen? </NavLink>
                    </div>
                    Noch kein Konto? <NavLink to="/registrierung">Jetzt registrieren&nbsp;&rsaquo;</NavLink>
                </div>
            </div>
        </div>
    )
}

export default Login