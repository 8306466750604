import { combineReducers, configureStore } from '@reduxjs/toolkit';
import globalReducer from './global/reducer';

import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    global: globalReducer    
});

const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
    reducer:persistedReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }),
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store)