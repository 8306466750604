import { NavLink } from "react-router-dom"

const FrontendError404 = () => {
    return (
        <div className={'fe-wrapper' + (/iPad|iPhone|iPod/.test(navigator.userAgent) ? ' ios-full' : '')}>
            <div className='box'>
                <span className='logo'></span>
                <div className='form'>
                    <form className="form-add">
                        <h2>404</h2>
                        <p>Die angeforderte Seite konnte leider nicht gefunden werden.</p>
                    </form>
                </div>
                <div className='options'>
                    <div className='smalltext last'>
                        <NavLink to="/">Zum Login</NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FrontendError404